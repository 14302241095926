import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CancelTransactionRequestRequest,
  GetMeNotificationsTransactionsRequest,
  NotificationsTransactionsPending,
  SummaryNotificationsTransactionsPending,
} from '@bp-core/src/lib/proto/portal-user.pb';
import { PortalUserGrpcServiceClient } from '@bp-core/src/lib/proto/portal-user.pbsc';
import { Int32Value } from '@ngx-grpc/well-known-types';
import { ReplaySubject, map } from 'rxjs';
import { ValidateTypeDto } from '../models/validate-type.dto';
import { BpConfirmationService } from './bp-confirmation.service';

@Injectable({
  providedIn: 'root',
})
export class BpPendingNotificationsService {
  private summaryPendingNotificationsSubject = new ReplaySubject<
    SummaryNotificationsTransactionsPending[] | undefined
  >(1);
  public readonly summaryPendingNotifications$ =
    this.summaryPendingNotificationsSubject.asObservable();

  private pendingNotificationsSubject = new ReplaySubject<
    NotificationsTransactionsPending[] | undefined
  >(1);
  public readonly pendingNotifications$ = this.pendingNotificationsSubject.asObservable();

  constructor(
    private portalUserGrpcServiceClient: PortalUserGrpcServiceClient,

    private bpConfirmationService: BpConfirmationService,
    private router: Router,
  ) {}

  getSummaryPendingNotifications(goalId?: number) {
    const getMeSummaryPendingNotificationsRequest = new GetMeNotificationsTransactionsRequest();
    if (goalId) {
      getMeSummaryPendingNotificationsRequest.goalId = new Int32Value({ value: goalId });
    }

    this.portalUserGrpcServiceClient
      .getMeSummaryNotificationsTransactionsPending(getMeSummaryPendingNotificationsRequest)
      .subscribe({
        next: notifications => {
          this.summaryPendingNotificationsSubject.next(notifications.values);
        },
        error: e => {
          console.error(e);
        },
      });
  }

  getPendingNotifications(goalId?: number) {
    const getMeSummaryPendingNotificationsRequest = new GetMeNotificationsTransactionsRequest();
    if (goalId) {
      getMeSummaryPendingNotificationsRequest.goalId = new Int32Value({ value: goalId });
    }
    this.portalUserGrpcServiceClient
      .getMeNotificationsTransactionsPending(getMeSummaryPendingNotificationsRequest)
      .subscribe({
        next: notifications => {
          this.pendingNotificationsSubject.next(notifications.values);
        },
        error: e => {
          console.error(e);
        },
      });
  }
  cancelNotification(transactionRequestId: number) {
    return this.portalUserGrpcServiceClient.cancelTransactionRequest(
      new CancelTransactionRequestRequest({
        id: transactionRequestId,
      }),
    );
  }

  validatePendingNotifications(params: ValidateTypeDto) {
    const { goalId, validateTypes, showConfirmation } = params;
    const getMeSummaryPendingNotificationsRequest = new GetMeNotificationsTransactionsRequest();
    getMeSummaryPendingNotificationsRequest.goalId = new Int32Value({ value: goalId });

    return this.portalUserGrpcServiceClient
      .getMeNotificationsTransactionsPending(getMeSummaryPendingNotificationsRequest)
      .pipe(
        map(notifications => {
          return notifications.values ?? [];
        }),
        map(notifications => {
          const hasPendingTransactions =
            notifications &&
            notifications?.length > 0 &&
            notifications.some(n => validateTypes.includes(n.type));
          if (hasPendingTransactions && showConfirmation) {
            this.bpConfirmationService.open({
              title: 'No es posible realizar esta operación',
              message:
                'No es posible realizar esta operación porque tienes transacciones pendientes',
              dismissible: false,
              actions: {
                confirm: {
                  label: 'Aceptar',
                  function: dialogRef => {
                    dialogRef.close();
                    this.router.navigate(['/goals', goalId], {
                      fragment: 'summary',
                    });
                  },
                },
              },
            });
          }

          return hasPendingTransactions;
        }),
      );
  }
}

// this.bpConfirmationService.open({
//   title: 'No es posible realizar esta operación',
//   message:
//     'No es posible realizar esta operación porque tienes transacciones pendientes',
//   dismissible: false,
//   actions: {
//     confirm: {
//       label: 'Aceptar',
//       function: dialogRef => {
//         dialogRef.close();
//         this.router.navigate(['/goals', goalId], {
//           fragment: 'summary',
//         });
//       },
//     },
//   },
// });
